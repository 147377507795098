<template>
  <v-sheet
    class="px-5 mx-auto py-4"
    style="max-width: 1000px;">
    <HomeAbout />
  </v-sheet>
</template>

<script>
export default {
  components: {
    HomeAbout: () => import('./About')
  }
}
</script>
